import axios from "axios"
import Md52 from '@/utils/md52.js';
import qs from 'qs';
const instance = axios.create({
    baseURL: process.env.NODE_ENV == "development" ? '/dev' : process.env.VUE_APP_BASE_API,
    timeout: 100000,
    //转换数据的方法
    // transformRequest: [
    //     function(data) {
    //         let ret = '';
    //         for (let it in data) {
    //             ret += encodeURIComponent(it) + '=' + encodeURIComponent(data[it]) + '&';
    //         }
    //         return ret;
    //     }
    // ],
});

function md5Data(data) {
    let signKey2 = 'cf4a356cfee1ebed6112f9273af7af22'; //加密关键字
    let str = "";
    for (let i in data) {
        str = str + (data[i]);
    }
    let str2 = signKey2 + str;
    return Md52.md5(str2);
}

// 添加请求拦截器
instance.interceptors.request.use((config) => {
    // content-type: "application/json; charset=utf-8;"
    // 在发送请求之前做些什么
    // console.log("请求拦截====>", config)
    // let TOKEN = Storage.get('token');
    let TOKEN = null;
    if (TOKEN) {
        config.headers['Token'] = TOKEN;
        // config.headers['Storeid'] = getStoreId();
    }
    if (config.method === 'post') {
        config.data = config.data ? config.data : {}
        config.data.t = new Date().getTime();
        config.data.sign = md5Data(config.data);
        if (!config['Content-Type']) {
            config.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8';
            config.data = qs.stringify(config.data)
        } else {
            config.headers['Content-Type'] = config['Content-Type'];
        }
    } else {
        config.params = config.params ? config.params : {}
        config.params.t = new Date().getTime();
        config.params.sign = md5Data(config.params);
    }

    return config;
}, function(error) {
    // 对请求错误做些什么
    return Promise.reject(error);
})

// 添加响应拦截器
instance.interceptors.response.use(function(response) {
    // 对响应数据做点什么
    if (response.data.resulet) {
        response = JSON.parse(response.data.resulet)
    } else if (response.data) {
        response = Promise.resolve(response.data)

    } else {
        return Promise.reject(response)
    }

    return response
}, function(error) {
    // 对响应错误做点什么
    return Promise.reject(error);
});

function axiosGet(url, params) {
    return instance.get(url, {
        params
    });
}

function axiosPost(url, params, config = {}) {
    return instance.post(url, params, config);
}
export {
    axiosGet,
    axiosPost,
    md5Data
}