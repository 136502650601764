var api = {
    carType: "/Car/getCarType", //获取车辆类型
    cargoType: '/Cargo/getOftenCargoods', //获取货物常用名词 x
    autoCarType: '/Cargo/cargoCarType', //自动匹配车辆类型
    calCost: '/Order/computeTotalFree', //计算费用
    appLogin: '/User/pc_login', //消费者注册/登录
    decryptCode: '/User/decryptCode', //解密手机号码 x
    submitOrder: '/Order/userConfirmOrder', //提交订单
    uploadImage: '/Index/uploadImage', //上传图片
    uploadFile: '/Index/uploadFile', //上传文件
    commonRoute: '/Address/userHaulwayList', //常用路线
    cannelTips: '/Article/userClearOrderMsgList', //取消订单提示语
    cannelOrder: '/Order/userClearOrder', //取消订单
    upgradeCar: '/Order/updateOrderCarConfirm', //升级成为专车
    showUpgrade: '/Order/updateOrderCarShow', //展示用户升级专车
    orderList: '/Order/orderList', //用户订单列表
    orderDetail: '/Order/OrderInfo', //用户订单详情
    addCommonRoute: '/Address/hostHaulway', //添加常用路线 x
    userCenter: '/User/userCenter', //用户中心
    deleteRooute: '/Address/delOftenWay', //删除常用路线
    orderVoucher: '/Order/certificateList', //获取订单凭证
    orderCar: '/Car/getCarInfo', //订单车辆信息 x
    getRemarks: '/Order/remarkLog', //获取备注 x
    receiveGoods: '/Order/receiveGoods', //确认收货
    commentDriver: '/Order/commentOrder', //评价司机 x
    bindPhone: '/User/bindPhone', //绑定手机号码 x
    getSessionKey: '/User/getSessionKey', //获取sessionKey x
    myCoupon: '/Coupon/couponList', //我的优惠券
    couponCenter: '/Coupon/userNeedCoupon', //获取可领取的优惠券
    receiveCoupon: '/Coupon/receiveCoupon', //领取优惠券
    getAbleCoupon: '/Coupon/orderCouponList', //获取订单可用的优惠券
    getComment: '/Order/getOrderComment', //获取订单评论 x
    getOrderPrice: '/Order/orderFreeInfo', //获取订单金额数据
    sendMsg: '/User/sendMsgToMotorman', //发送消息 x
    getMsg: '/User/userChanList', //获取消息列表 x
    getTime: '/Cargo/getCutAddressTime', //获取后台设置的时间
    feedback: '/User/addIdea', //反馈信息 x
    myBalance: '/User/balanceList', //我的余额
    recharge: '/User/addBalance', //余额充值 x
    getPayType: '/Pay/payTypeList', //获取支付方式
    submitPay: '/Pay/orderPay', //提交支付 x
    integral: '/User/scoreList', //我的积分 x
    breakList: '/User/getUserBreakList', //违约记录 x
    myTeam: '/User/userChildList', //我的团队 x
    alertAd: '/Index/getApiAdList', //广告弹窗 x
    getWarehouseList: 'Cargo/getWarehouseList', //获取入库,仓库列表
    articleList: 'Article/getArticleList', //文章列表
    articleDetail: 'Article/articleInfo', //文章详情
}
export default api;