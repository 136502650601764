<template>
  <div :height="fullHeight" :width="fullWidth">
    <img :src="bg" :height="fullHeight" />
    <!-- <a href=""> <img class="down_2" :src="but"/></a> -->
  </div>
</template>
<script>
export default {
  data() {
    return {
      bg: require("@assets/imgs/download_01.jpg"),
      but: require("@assets/imgs/download_02.png"),
      fullWidth: document.documentElement.clientWidth,
      fullHeight: document.documentElement.clientHeight
    };
  },
  created() {},
  mounted() {},
  methods: {}
};
</script>
<style lang="scss">
.down_2 {
  position: fixed;
  bottom: 20%;
  width: 20%;
  left: 35%;
}
.register {
  width: 100%;
  height: 1060px;
  /* 固定高度 */
  background-image: url("~@assets/imgs/download_01.jpg");
  background-position: 0 0;
  background-repeat: no-repeat;
}
</style>
