import Vue from "vue";
import VueRouter from "vue-router";
import Index from '@views/index'
import Info from '@views/info'
import Apk from '@views/apk'
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location, onResolve, onReject) {
    if (onResolve || onReject) {
        return originalPush.call(this, location, onResolve, onReject)
    }
    return originalPush.call(this, location).catch(err => err)
}

Vue.use(VueRouter);

const routes = [{
        path: "/login",
        name: "Login",
        // route level code-splitting
        // this generates a separate chunk (login.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import ( /* webpackChunkName: "login" */ "@views/login.vue")
    },
    {
        path:   '/',
        component:  Info,
        redirect:   '/info',
    },
	{
	    path:   '/apk',
	    component:  Apk,
	     name: "Apk",
	},
    {
        path: "/index",
        name: "Index",
        // route level code-splitting
        // this generates a separate chunk (index.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import ( /* webpackChunkName: "index" */ "@views/index.vue"),
        children: [{
            path: 'placeOrder',
            name: 'PlaceOrder',
            // route level code-splitting
            // this generates a separate chunk (placeOrder.[hash].js) for this route
            // which is lazy-loaded when the route is visited.
            component: () =>
                import ( /* webpackChunkName: "placeOrder" */ "@coms/placeOrder.vue"),
            meta: { title: '下单功能' }
        }, {
            path: 'orderList',
            name: 'OrderList',
            // route level code-splitting
            // this generates a separate chunk (orderList.[hash].js) for this route
            // which is lazy-loaded when the route is visited.
            component: () =>
                import ( /* webpackChunkName: "orderList" */ "@coms/orderList.vue"),
            meta: { title: '我的订单' }
        }, {
            path: 'payOrder',
            name: 'PayOrder',
            // route level code-splitting
            // this generates a separate chunk (payOrder.[hash].js) for this route
            // which is lazy-loaded when the route is visited.
            component: () =>
                import ( /* webpackChunkName: "payOrder" */ "@coms/payOrder.vue"),
            meta: { title: '支付订单' }
        }, {
            path: 'matchOrder',
            name: 'MatchOrder',
            // route level code-splitting
            // this generates a separate chunk (matchOrder.[hash].js) for this route
            // which is lazy-loaded when the route is visited.
            component: () =>
                import ( /* webpackChunkName: "matchOrder" */ "@coms/matchOrder.vue"),
            meta: { title: '匹配订单' }
        }, {
            path: 'booking',
            name: 'Booking',
            // route level code-splitting
            // this generates a separate chunk (booking.[hash].js) for this route
            // which is lazy-loaded when the route is visited.
            component: () =>
                import ( /* webpackChunkName: "booking" */ "@coms/booking.vue"),
            meta: { title: '预约入仓' }
        }, {
            path: 'priceDetail',
            name: 'PriceDetail',
            // route level code-splitting
            // this generates a separate chunk (priceDetail.[hash].js) for this route
            // which is lazy-loaded when the route is visited.
            component: () =>
                import ( /* webpackChunkName: "priceDetail" */ "@coms/priceDetail.vue"),
            meta: { title: '价格明细' }
        }, {
            path: 'checkOrder',
            name: 'CheckOrder',
            // route level code-splitting
            // this generates a separate chunk (checkOrder.[hash].js) for this route
            // which is lazy-loaded when the route is visited.
            component: () =>
                import ( /* webpackChunkName: "checkOrder" */ "@coms/checkOrder.vue"),
            meta: { title: '查看订单' }
        }, {
            path: 'mapChoice',
            name: 'mapChoice',
            // route level code-splitting
            // this generates a separate chunk (mapChoice.[hash].js) for this route
            // which is lazy-loaded when the route is visited.
            component: () =>
                import ( /* webpackChunkName: "mapChoice" */ "@coms/mapChoice.vue"),
            meta: { title: '选择地址' }
        }, {
            path: 'articleList',
            name: 'ArticleList',
            // route level code-splitting
            // this generates a separate chunk (articleList.[hash].js) for this route
            // which is lazy-loaded when the route is visited.
            component: () =>
                import ( /* webpackChunkName: "articleList" */ "@coms/articleList.vue"),
            meta: { title: '文章列表' }
        }, {
            path: 'articleDetail',
            name: 'ArticleDetail',
            // route level code-splitting
            // this generates a separate chunk (articleDetail.[hash].js) for this route
            // which is lazy-loaded when the route is visited.
            component: () =>
                import ( /* webpackChunkName: "articleDetail" */ "@coms/articleDetail.vue"),
            meta: { title: '文章详情' }
        }]
    },
    {
        path: "/info",
        name: "Info",
        component: Info
    },
    {
        path: '/loginSuccess',
        name: 'loginSuccess',
        // route level code-splitting
        // this generates a separate chunk (loginSuccess.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import ( /* webpackChunkName: "loginSuccess" */ "@views/loginSuccess.vue"),
        meta: { title: '登陆成功' }
    }
];

const router = new VueRouter({
    routes
});

export default router;