<template>
    <div class="no_scroll">
        <div class="side_wrapper">
            <div class="side_scrollbar">
                <div class="scrollbar_box">
                    <div class="side_nav">
                        <img class="logo_img" src="@assets/imgs/wyt_04.jpg" alt="">
                        <ul>
                            <li class="nav_li"><router-link class="link_span" tag="span" to="/index/placeOrder">下单</router-link></li>
                            <li class="nav_li"><router-link class="link_span" tag="span" to="/index/matchOrder">匹配</router-link></li>
                            <li class="nav_li"><router-link class="link_span" tag="span" to="/index/orderList">订单</router-link></li>
                            <li class="nav_li"><router-link class="link_span" tag="span" to="/index/booking">预约</router-link></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <div class="main_cont">
            <div class="main_scroll">
                <div class="main_view">
                    <div class="main_header">
                        {{this.page_title}}
                    </div>
                    <div class="main_content" v-cloak>
                        <div class="main_area">
                            <keep-alive include="placeOrder">
                                <router-view v-if="isReload" :key="key"></router-view>
                            </keep-alive>
                        </div>
                    </div>
                </div>
            </div>
            <div class="right_side">
                <div class="right_side_wrap">
                    <div class="right_header flex_between_center">
                        <img :src="userData && userData.avatar ? userData.avatar : '@assets/imgs/u910.png'" alt="">
                        <div class="account_name">{{userData && userData.username || '请登录'}}</div>
                        <div class="logout_text" @click.stop="toLogout">[注销]</div>
                    </div>
                    <div class="right_body column_between_center">
                        <div class="explain right_explain">
                            <div class="ex_view">
                                <div class="ex_header flex_between_center">
                                    <span class="ex_title">操作说明</span>
                                    <span class="iconfont iconright icon_right"></span>
                                </div>
                                <div class="ex_list" v-if="article_count > 0">
                                    <ul>
                                        <li class="ex_li more_ellipsis" 
                                            v-for="item in getNearArctle" 
                                            :key="item.id" 
                                            @click.stop="toArticleDetail(item)">
                                            {{item.title}}
                                        </li>
                                    </ul>
                                    <span class="more_text" v-if="article_count > 3" @click.stop="toMoreArticle">查看更多</span>
                                </div>
                            </div>
                        </div>
                        <div class="code_view">
                            <span class="code_title">外运通手机版扫描</span>
                            <img src="@assets/imgs/u30.png" class="code_img" alt="">
                            <span class="code_tips">扫码关注，手机查看动态</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {handleGetArticleList } from "@api/index"
export default {
    provide(){
        return {
            app:this
        }
    },
    data() {
        return {
            articleList:[],
            article_count:0,
            isReload:true
        }
    },
    created() {
        
    },
    mounted() {
        if(this.userData != null){
            this.onGetArticleList()
        }
    },
    methods: {
        onReload(){
            this.isReload = false;
            this.$nextTick(_=>{
                this.isReload = true;
            })
        },
        toLogout(){
            // this.$store.dispatch('logout', {})
            // .then(() => {
            //     this.$router.replace({path:"/login"})
            // })
            // .catch(() => {
            // })
            this.$store.dispatch('resetToken').then(_=>{
                this.$storage.remove('userData')
                this.$router.replace('/login')
            })
        },
        toArticleDetail(data){
            let item = data;
            this.$router.push({path:'articleDetail',query:{article_id:item.id}})
            if(this.$route.name == 'ArticleDetail'){
                this.onReload()
            }
        },
        toMoreArticle(){
            this.$router.push({name:'ArticleList'})
        },
        onGetArticleList(){
            let datakey = {};
            datakey['user_sn'] = this.userData.user_sn;
            datakey['page'] = 1;
            datakey['user_type'] = 3
            handleGetArticleList(datakey).then(res=>{
                if(res.status == 0){
                    this.articleList = res.data.list;
                    this.article_count = res.data.count
                }
            }).catch(err=>{
                console.log(err)
            })
        }
    },
    computed:{
        page_title(){
            return this.$store.getters.pagetitle
        },
        key() {
            return this.$route.path
        },
        userData:function(){
            return this.$store.getters.userData || this.$storage.get('userData')
        },
        getNearArctle(){
            return Array.isArray(this.articleList)?this.articleList.slice(0,5):[]
        }
    }
}
</script>

<style lang="scss">
.no_scroll {
    overflow-y: hidden;
    background: #f7f8fa;
    min-height: 688px;
}

.header_wrapper {
    position: absolute;
    width: 100%;
    left: 0;
    top: 0;
    z-index: 1500;
}

.header {
    height: 80px;
    background-color: #fff;
    color: #fff;
    top: 0;
    left: 0;
    width: 100%;
    line-height: 80px;
    z-index: 100;
    position: relative;
}

.side_wrapper {
    position: fixed;
    width: 100%;
    left: 0;
    top: 0;
    z-index: 1500;
    width: 100px;
    background: #333;
    overflow: hidden;
}

.side_scrollbar {
    height: 100vh;
    overflow-x: hidden;
    overflow-y: scroll;
    margin-right: -17px;
}

.side_nav {
    height: 100%;
    padding-top: 50px;
    padding-bottom: 50px;
    padding-right: 0;
    width: 100%;
    box-sizing: border-box;
}

.logo_img {
    width: 60px;
    height: 60px;
    border-radius: 60px;
    color: #333;
    text-align: center;
    line-height: 60px;
    font-size: 14px;
    margin: 0 auto 10px;
    object-fit: cover;
}

.side_nav ul {
    padding: 0;
    margin: 0;
    overflow: hidden;
    padding-bottom: 50px;
    .link_span{
        line-height: 40px;
        height: 40px;
        display: block;
    }
}

.nav_li {
    font-size: 14px;
    color: #fff;
    line-height: 40px;
    height: 40px;
    margin: 0;
    padding: 0;
    display: block;
    position: relative;
    transition: .15s ease-out;
    text-align: center;
    cursor: pointer;
}

.nav_li:hover {
    color: #02a7f0;
}


/* 主体 */

.main_cont {
    padding: 0;
    margin: 0;
    width: 100%;
    min-width: auto;
    box-sizing: border-box;
    display: flex;
}

.main_scroll {
    margin-left: 100px;
    height: 100vh;
    min-height: 688px;
    width: 100%;
}

.main_view {
    flex: 1 1;
    height: 100%;
    min-height: auto;
    position: relative;
    overflow: hidden;
}

.main_header {
    height: 60px;
    line-height: 60px;
    font-size: 15px;
    color: #333;
    padding: 0 20px;
    border-right: 2px solid #ddd;
    border-bottom: 2px solid #ddd;
    width: 100%;
    background: #fff;
    text-align: left;
}

.main_content {
    /* margin: 17px 0 40px 17px; */
    /* padding-right: 17px; */
    width: 100%;
    height: calc(100% - 56px);
    box-sizing: border-box;
    position: relative;
}

.main_area {
    width: calc(100% - 34px);
    background: white;
    overflow-x: hidden;
    overflow-y: auto;
    height: calc(100% - 40px);
    position: absolute;
    left: 0;
    top: 17px;
    right: 0;
    margin: auto;
    scrollbar-color: transparent transparent;
    scrollbar-track-color: transparent;
    -ms-scrollbar-track-color: transparent;
    scrollbar-width: none;
    -ms-overflow-style: none;
}

.main_area::-webkit-scrollbar {
    display: none;
}

/*#region*/

.right_side {
    height: 100vh;
    min-height: 688px;
    width: 300px;
    background: #fff;
}

.right_side_wrap {
    height: 100%;
    padding-bottom: 40px;
    box-sizing: border-box;
    overflow: hidden;
}

.right_header {
    height: 60px;
    padding: 0 20px;
    border-bottom: 2px solid #ddd;
}

.right_header img {
    width: 30px;
    height: 30px;
    border-radius: 30px;
    margin-right: 4px;
}

.account_name {
    flex: 1 1;
    font-size: 14px;
    color: #333;
}

.logout_text {
    font-size: 13px;
    color: #02a7f0;
    padding-left: 4px;
    cursor: pointer;
}

.logout_text:hover {
    text-decoration: underline;
}

.right_body {
    height: calc(100% - 60px);
    min-height: auto;
}

.explain {
    padding: 0 10px;
    width: 100%;
}

.explain .ex_view {
    padding: 10px 0;
    border-bottom: 1px solid #ddd;
}

.ex_header {
    height: 40px;
}

.icon_right {
    padding-top: 2px;
}

.ex_title {
    font-size: 14px;
    font-weight: 600;
    color: #333;
}

.ex_li {
    font-size: 14px;
    color: #333;
    line-height: 1.3;
    margin-bottom: 8px;
    text-align: left;
    cursor: pointer;
}

.ex_li:hover {
    text-decoration: underline;
}

.more_text {
    color: #02a7f0;
    font-size: 13px;
    width: 100%;
    display: block;
    line-height: 1.4;
    text-align: right;
    cursor: pointer;
}

.more_text:hover {
    text-decoration: underline;
}

.code_view {
    text-align: center;
}

.code_view span {
    display: block;
}

.code_title {
    font-size: 14px;
    color: #333;
    margin: 0 auto 10px;
}

.code_img {
    width: 200px;
    height: 200px;
    margin: 0 auto 10px;
}

.code_tips {
    color: #02a7f0;
    font-size: 14px;
    text-align: center;
}


/*#endregion*/
</style>