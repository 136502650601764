import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import "./loginRouter";
import Storage from 'good-storage';
import VueBus from 'vue-bus';

import "@assets/css/main.scss"
import { axiosGet, axiosPost } from "@/api/request"
import { Button, Pagination, Progress, Dialog, Input, Switch, DatePicker, TimePicker, TimeSelect, Tag, Upload, Card, Rate, Steps, Step, Carousel, CarouselItem, Form, FormItem, Select, Option, OptionGroup, MessageBox, Message, Loading, Notification,Image } from 'element-ui';

Vue.config.productionTip = false;
Vue.use(Button).use(Pagination).use(Progress).use(Dialog).use(Input).use(Switch).use(DatePicker).use(TimePicker).use(TimeSelect).use(Tag).use(Upload).use(Card).use(Rate).use(Steps).use(Step).use(Carousel).use(CarouselItem).use(Form).use(FormItem).use(Select).use(Option).use(OptionGroup).use(Image)
Vue.use(Loading.directive);
Vue.use(VueBus);
Vue.prototype.$loading = Loading.service;
Vue.prototype.$msgbox = MessageBox;
Vue.prototype.$alert = MessageBox.alert;
Vue.prototype.$confirm = MessageBox.confirm;
Vue.prototype.$prompt = MessageBox.prompt;
Vue.prototype.$notify = Notification;
Vue.prototype.$message = Message;
Vue.prototype.$get = axiosGet;
Vue.prototype.$post = axiosPost;
Vue.prototype.$storage = Storage;
new Vue({
    router,
    store,
    render: h => h(App)
}).$mount("#app");