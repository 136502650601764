import Vue from "vue";
import Vuex from "vuex";
import { getToken, setToken, removeToken } from '@/utils/auth';
import { handleLogin } from '@api/login'
import { handleCalCost } from '@api/global';
import { Message } from 'element-ui'

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        pagetitle: '', //页面标题
        token: '',
        userData: null,
        costData: {}, //价格数据
        isSaveRoute: true,
        calCost: true, //是否已计算价格
        mapRoute: null,

        carList_index: 0,
        pendant_id: [],
        selectCar: '', //选中的车型

        startLocation: {
            address: '',
            floor: '',
            linkman_name: '',
            linkman_phone: ''
        }, //装货地点
        startWayPoint: [], //装货途经点
        endLocation: {
            address: '',
            floor: '',
            linkman_name: '',
            linkman_phone: ''
        }, //卸货地点
        endWayPoint: [], //卸货途径点
        loc_type: '', //地点类型   start=>装货 end=>卸货 point=>途经点
        action: '', //途经点类型        1=>装货  0=>卸货
        point_index: '',
    },
    mutations: {
        SET_PAGETITLE: (state, pagetitle) => {
            state.pagetitle = pagetitle;
        },
        SET_TOKEN: (state, token) => {
            state.token = token;
        },
        SET_USERDATA: (state, data) => {
            state.userData = data;
        },
        SET_COSTDATA: (state, obj) => {
            state.costData = obj;
        },
        SET_ISSAVEROUTE: (state, boo) => {
            state.isSaveRoute = boo;
        },
        SET_CALCOST: (state, boo) => {
            state.calCost = boo;
        },
        SET_MAPROUTE: (state, route) => {
            state.mapRoute = route
        },

        SET_PENDANTID: (state, data) => {
            state.pendant_id = data;
        },
        SET_CARLIST_INDEX: (state, data) => {
            state.carList_index = data;
        },
        SET_SELECTCAR: (state, select) => {
            state.selectCar = select;
        },
        // 地点设置
        SET_STARTLOCATION: (state, data) => {
            state.startLocation = data;
        },
        SET_ENDLOCATION: (state, data) => {
            state.endLocation = data;
        },
        SET_STARTWAYPOINT: (state, data) => {
            state.startWayPoint = data;
        },
        SET_ENDWAYPOING: (state, data) => {
            state.endWayPoint = data;
        },
        SET_LOCTYPE: (state, payload) => {
            state.loc_type = payload;
        },
        SET_ACTION: (state, payload) => {
            state.action = payload;
        },
        SET_POINT_INDEX: (state, payload) => {
            state.point_index = payload;
        }
    },
    getters: {
        pagetitle: state => state.pagetitle,
        costData: state => state.costData,
        userData: state => state.userData,
        isSaveRoute: state => state.isSaveRoute,
        calCost: state => state.calCost,
        carList_index: state => state.carList_index,
        pendant_id: state => state.pendant_id,
        selectCar: state => state.selectCar,
        loc_type: state => state.loc_type,
        action: state => state.action,
        startLocation: state => state.startLocation,
        endLocation: state => state.endLocation,
        startWayPoint: state => state.startWayPoint,
        endWayPoint: state => state.endWayPoint,
        point_index: state => state.point_index
    },
    actions: {
        calCost({ commit }, payload) {
            return new Promise((resolve, reject) => {
                console.log("payload==>", payload)
                let { startLocation, endLocation, wayPoint, trip_type, back_car, car_type_id, pendant_id } = payload;
                let datakey = {};
                datakey['start_location'] = JSON.stringify(startLocation)
                datakey['end_location'] = JSON.stringify(endLocation)
                datakey['trip_type'] = trip_type
                datakey['back_car'] = back_car
                datakey['car_type_id'] = car_type_id
                if (wayPoint.length > 0) {
                    datakey['way_point'] = JSON.stringify(wayPoint)
                }
                if (pendant_id) {
                    datakey['pendant_id'] = pendant_id
                }
                handleCalCost(datakey).then(datas => {
                    console.log(datas)
                    if (datas.status == 0) {
                        let costData = datas.data
                        let distance = costData.distance
                        let init_km = costData.init_km
                        let canBack = 1
                        if (parseFloat(distance) < parseFloat(init_km)) {
                            canBack = 0
                        }
                        costData.canBack = canBack;
                        commit('SET_COSTDATA', costData)
                        commit('SET_ISSAVEROUTE', false)
                        commit('SET_CALCOST', false)
                        resolve(costData)
                    } else {
                        Message.info('价格计算错误')
                    }
                }).catch(err => {
                    console.log(err)
                    reject(err)
                })
            })

        },
        // user login
        login({ commit }, payload) {
            console.log("55555")
            const { code } = payload;
            return new Promise((resolve, reject) => {
                let datakey = {};
                datakey['code'] = code;
                handleLogin(datakey).then(response => {
                    if (response.status == 0) {
                        commit('SET_USERDATA', response.data.user)
                            // commit('SET_TOKEN', 'Is-Token')
                            // setToken('Is-Token')
                        Message.success('登录成功')
                        resolve(response)
                    } else {
                        Message.error(response.msg)
                        reject(response)
                    }
                }).catch(error => {
                    Message.error(error.msg)
                    reject(error)
                })
            })
        },
        // get user info
        getInfo({ commit, state }) {
            // return new Promise((resolve, reject) => {
            //     getInfo(state.token).then(response => {
            //         const { data } = response

            //         if (!data) {
            //             reject('Verification failed, please Login again.')
            //         }

            //         const { roles, name, avatar, introduction } = data

            //         // roles must be a non-empty array
            //         if (!roles || roles.length <= 0) {
            //             reject('getInfo: roles must be a non-null array!')
            //         }

            //         commit('SET_NAME', name)
            //         commit('SET_AVATAR', avatar)
            //         commit('SET_INTRODUCTION', introduction)
            //         resolve(data)
            //     }).catch(error => {
            //         reject(error)
            //     })
            // })
        },
        // user logout
        logout({ commit, state, dispatch }) {
            removeToken()
                // return new Promise((resolve, reject) => {
                //     logout(state.token).then(() => {
                //         commit('SET_TOKEN', '')
                //         removeToken()
                //         resetRouter()
                //         // reset visited views and cached views
                //         // to fixed https://github.com/PanJiaChen/vue-element-admin/issues/2485
                //         dispatch('tagsView/delAllViews', null, { root: true })
                //         resolve()
                //     }).catch(error => {
                //         reject(error)
                //     })
                // })
        },
        // remove token
        resetToken({ commit }) {
            return new Promise(resolve => {
                // commit('SET_TOKEN', '')
                commit('SET_USERDATA', null)
                    // removeToken()
                resolve()
            })
        },

    },
    modules: {}
});