<template>
  <div class="info_container">
    <img src="@assets/imgs/wyt_01.jpg" alt="" />
    <img src="@assets/imgs/wx_nobg.png" id="topcode" />
    <div class="info_center">
      <div class="center_header">
        <img src="@assets/imgs/wyt_04.jpg" alt="" />
        <div class="header_text">
          外运通是一款线上物流运输服务中心。为广大货主与车主提供一个良好的沟通平台，智能匹配车型，随时发布运输订单，实时监控货物，收费信息明了，账目清除。
          <!-- <el-button type="primary" class="login_btn" @click.stop="$router.push('/login')">登录用车</el-button> -->
          <el-button type="primary" class="login_btn" @click="downloadFile(dl)"
            >司机端下载</el-button
          >
        </div>
      </div>
      <img src="@assets/imgs/wyt_07.jpg" alt="" />
      <img src="@assets/imgs/wyt_10.jpg" alt="" />
      <img src="@assets/imgs/wyt_12.jpg" alt="" />
    </div>
    <div class="info_bottom column_center_start">
      <!-- <img src="@assets/imgs/u30.png" alt="" /> -->
      <img src="@assets/imgs/wx_nobg.png" id="nocolor" />
      <span class="info_txt1">进出口提送货物</span>
      <span class="info_txt2">首选外运通</span>
      <a
        href="https://beian.miit.gov.cn"
        target="_blank"
        rel="nofollow"
        class="info_txt3"
        >粤ICP备18104377号-1</a
      >
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      dl:
        "https://dl.fenghuayunshu.com/apk/%E5%A4%96%E8%BF%90%E9%80%9A.apk"
    };
  },
  created() {},
  mounted() {},
  methods: {
    downloadFile(fileUrl) {
      const link = document.createElement("a");
      link.href = fileUrl;
      link.setAttribute("download", fileUrl);
      link.click();
    }
  }
};
</script>
<style lang="scss">
#nocolor {
  height: 200px;
}
#topcode {
  height: 200px;
  float: right;
  z-index: 99999;
  margin-top: -200px;
}
.info_container {
  width: 100%;
  .info_center {
    margin: 0 auto;
    width: 1140px;
    img {
      width: 100%;
      margin-bottom: 20px;
    }
    .center_header {
      padding: 30px 0;
      display: flex;
      img {
        width: 223px;
        height: 223px;
        margin-bottom: 0;
        margin-right: 20px;
      }
      .header_text {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        font-size: 14px;
        line-height: 1.4;
        color: #333;
        word-break: break-all;
        text-align: left;
        position: relative;
      }
      .login_btn {
        position: absolute;
        right: 0;
        bottom: 0;
      }
    }
  }
  img {
    width: auto;
    height: auto;
  }
  .info_bottom {
    background: #00a0ee;
    img {
      margin: 20px auto 10px;
    }
    .info_txt1 {
      text-align: center;
      line-height: 1.2;
      font-size: 15px;
      padding-bottom: 10px;
      color: #fff;
    }
    .info_txt2 {
      font-size: 24px;
      font-weight: bold;
      color: #fff;
      line-height: 1.2;
      padding-bottom: 20px;
      text-align: center;
    }
    .info_txt3 {
      font-size: 13px;
      color: #fff;
      padding-bottom: 20px;
      line-height: 1.2;
      text-align: center;
    }
  }
}
</style>
